.tableInfoHeader tr td,
.tableInfoHeader tr th {
  border: none;
}

/* .css-1c2m6kz {
  border-bottom: 1px solid;
}
.css-zp2dtr,
.css-1c2m6kz,
header {
  background-color: #1890ff !important;
  color: white;
}

.css-1yl4b1w-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: #1890ff;
  color: white;
  font-weight: bold;
}
.css-1ei61u3-MuiTypography-root {
  color: white !important;
}
.css-1sx7tm3-MuiTypography-root,
.anticon {
  color: white !important;
}

 button[aria-label="open drawer"] {
  background-color: red;
}
button[aria-label="open drawer"] + div {
  width: 100%;
}

.css-nm9qzb {
  color: white;
}
*/
/* body {
  background-color: #eef8ff96 !important;
} */

.MuiBox-root.css-1c2m6kz {
  color: white;
  background: linear-gradient(to right, rgb(0, 39, 101), rgb(9, 107, 185));
}
/* .MuiTableCell-root {
  font-size: 0.75rem !important;
} */

.label {
  font-weight: 600;
}

.datatable {
  /* overflow-x: auto; */
  width: 100%;
  background-color: white;
  margin-top: 2rem;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.MuiDataGrid-root {
  min-height: 15rem;
}
/* .MuiDataGrid-columnSeparator--sideRight{
  color: rgba(0, 0, 0, 0.566) !important;
} */
.MuiDataGrid-row {
  border-bottom: 2.2px solid rgb(0 0 0 / 28%);
}
.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid rgba(0, 0, 0, 0.566);
  border-top: 1px solid rgba(0, 0, 0, 0.566);
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  border-right: 1px solid rgb(0 0 0 / 28%);
}
.MuiDataGrid-columnsContainer,
.MuiDataGrid-cell {
  border-bottom: 1px solid rgb(0 0 0 / 28%);
}
.MuiDataGrid-iconSeparator {
  display: none;
}

.monthlyTable .MuiPaper-root {
  border-top: 1px solid rgba(0, 0, 0, 0.246);
  box-shadow: none;
  margin-top: 1rem;
  border-radius: unset;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
  width: 100vw;
}
.tss-1qtl85h-MUIDataTableBodyCell-root {
  font-weight: 600;
}

.cardRow {
  display: flex;
  justify-content: center;
}

.chartContainer {
  margin: 2.5rem auto;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
  border-radius: 8px;
}

.chartFormWrapper {
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem 0;
  margin-left: 4px !important;
  width: 100%;
}

.MonthlyContainer {
  font-family: "Public Sans, sans-serif";
  border-radius: 5px;
  /* width: 96vw; */
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
  overflow-x: hidden;
  background: "red";
}
.monthly-table > tbody > tr > td:first-child {
  white-space: nowrap;
}
.monthly-table > tbody > tr:last-child > td {
  background: lightgray;
  font-weight: bold;
  font-size: 14px !important;
}
.monthly-table > tbody > tr:hover {
  background: #d4deed;
  font-weight: bold;
  font-size: 14px !important;
}

.table-header > .MuiTableCell-root {
  font-weight: bold;
}
.monthly-table > thead > tr > th {
  font-weight: normal;
  border: none;
}
