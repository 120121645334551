/* body {
    background-color: rgb(240, 235, 235);
} */

.loginForm {
    background-color: white;
    padding: 20px 50px;
    margin: 10px 0;
    width: 410px;
    height: 450px;
    border-radius: 5px;
    letter-spacing: 1px;
    -webkit-box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.38);
    box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.38);
    
    /* Glass morphism added */
    backdrop-filter: blur(3px) saturate(0%);
    -webkit-backdrop-filter: blur(3px) saturate(0%);
    /* background-color: rgba(243, 243, 243, 0.39); */
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}

.heading {
    font-family: Arial;
    text-align: center;
    color: darkblue;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
}

.inputWrapper {
    padding-bottom: 12px;
}

.inputWrapper>input {
    width: 100%;
    padding: 10px 10px;
    margin: 5px 0;
    border: 1px solid rgb(194, 189, 189);
    border-radius: 4px;
    box-sizing: border-box;
}

.inputWrapper>label {
    font-family: Arial;
    font-size: 85%;
    font-weight: bold;
    color: rgb(30, 30, 30);
}

.inputWrapper>label::after {
    content: "*";
    color: red;
}
/* 
button {
    width: 100%;
    font-weight: bold;
    background-color: #1890ff;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
}

button:hover {
    background-color: #0364bf;
} */

.loginForm {
    margin: auto
}

.LoginWrapper {
    background-image: url("../../../assets/images/auth/loginBg.png");
    height: 100%;
    width: 100vw;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    background-position: center;
    display: flex;
    justify-content: center;
    top: 0;
    background-size: cover;
    align-items: center;
}


