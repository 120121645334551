.lineform {
  margin-bottom: 1%;
}

/* ----------DATA ENTRY FORM CSS----------- */

.box {
  border: 1px solid #bababa;
}

.leftSection {
  grid-area: leftSection;
  border-radius: 4px;
}

.rightSection {
  grid-area: rightSection;
  display: grid;
  grid-template-columns: 300px 100px 1fr;
  grid-template-areas:
    "rSection1  rSection2  rSection3"
    "rSection1 rSection2 rSection3"
    "null  rSection2  rSection3";
  border-radius: 4px;
}

.R-section1 {
  grid-area: rSection1;
  border: none;
}

.R-section2 {
  grid-area: rSection2;
  border-left: 1px solid #bababa;
  border-top: none;
  border-bottom: none;
}

.R-section3 {
  grid-area: rSection3;
  border: 1px solid #bababa;
  display: flex;
  flex-direction: column;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.R-section3-child1 {
  height: 5rem;
  display: flex;
  justify-content: space-around;
  flex: 0;
  flex-wrap: wrap;
  padding-top: 8px;
}

.R-section3-child2 {
  /* height: 20rem */
}

.R-section3-child3 {
  /* height: 20rem; */
  padding-left: 5px;
}

.headerSection {
  grid-area: headerSection;
  border-radius: 4px;
}

.footerSection {
  grid-area: footerSection;
  border-radius: 4px;
}

.form-container {
  display: grid;
  /* height: 100%; */
  grid-gap: 5px;
  grid-template-columns: 300px 2fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    "headerSection  headerSection  headerSection"
    "leftSection rightSection rightSection"
    "leftSection  footerSection  footerSection";
  color: #444;
}

.form-wrapper {
  /* height: calc(100vh - 60px) */
}

.form-label {
  font-weight: 600;
  white-space: normal;
}

.flexElement {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.textArea {
  flex-grow: 1;
}

.footerBtn {
  flex-grow: 1;
  display: flex;
  justify-content: start;
  align-items: center;
}

.paddingLeft {
  padding-left: 5px;
}

.paddingTop {
  padding-top: 5px;
}

.formTitle {
  width: 80%;
  margin: auto;
  color: darkblue;
  text-align: center;
  margin-bottom: 1rem;
}

/* ------------------- CREATE CUSTOMER---------------------- */

.masterCrudForm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
  place-items: center;
  place-content: center;
}
.customerForm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
  place-items: center;
  place-content: center;
}
.createCustomerWrapper {
  width: 75%;
  margin: auto;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
}

.viewMode {
  box-shadow: unset !important;
}

.actionBtns {
  display: flex;
  justify-content: center;
  /* padding: 1rem 0; */
}
.crudBtnpadding {
  padding: 1rem 0;
}

input#mui-6,
input#mui-5 {
  height: 1em;
}
.card-hover:hover {
  border: 1px solid rgb(0, 102, 255);
}

.disabled-input {
  background-color: #e4e1e169;
  -webkit-text-fill-color: #232323 !important;
  color: black;
}
